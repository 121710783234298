<div *ngIf="!isConfirmVisible" class="input-group">
    <select class="custom-select" [(ngModel)]="selectedCategory">
        <option *ngFor="let category of categories" [ngValue]="category">
            <b>{{ category.categoryTag }} </b>
            <span class="element-text">{{ category.fullName }}</span>
        </option>
    </select>
    <div class="input-group-append">
        <button class="btn btn-primary"
        (click)="toggleConfirm()" #button>{{ 'console.generate-ticket' | translate }}</button>
    </div>
</div>

<div *ngIf="isConfirmVisible">
    <h5 class="title">{{ 'console.generate-ticket-confirm' | translate}}</h5>
    <div class="input-group-append">
        <button class="btn btn-secondary mr-1" (click)="toggleConfirm()" #button>{{ 'cancel' | translate }}</button>
        <button class="btn btn-success" (click)="generateTicket()" #button>{{ 'submit' | translate }}</button>
    </div>
</div>